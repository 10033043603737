import React from 'react'
import '../assets/scss/layout.scss'
import '../assets/scss/success-error.scss'

const Content = () => {
    return (
        <div className="page-wrapper">
            <div className="page-main width-container">
                <main className="width-limiter">
                    <div className="form-wrapper">
                        <div className="success-error">
                            <div className="success-error__icon-container" />

                            <div className="form-wrapper__heading success-error__heading">
                                Page not found
                            </div>

                            <p className="success-error__message p-l">
                                You may have mistyped the address
                                <br /> or the page has moved.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Content
